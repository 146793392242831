<template>
  <div
    style="
      margin-left: -10px;
      margin-right: -10px;
      padding: 10px;
      margin-top: -23px;
      padding-top: 10px;
      margin-bottom: 20px;
      background: white;
    "
    class="d-sm-none"
  >
    <p class="gray-text fs-12">Все сервисы</p>
    <div class="d-flex flex-wrap justify-content-between">
      <b-button
        :to="{ name: 'advertising-gathering' }"
        class="px-1 planc_button--short"
        variant="outline-default"
      >
        <img class="d-inline-block" src="@main/assets/img/svg/planc_icons/gathering.svg" alt="" />
        Размещение карточки
      </b-button>
      <b-button
        :to="{ name: 'blogger-checker' }"
        class="px-1 planc_button--short"
        variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/check.svg" alt="" />
        Проверка блогеров</b-button
      >
      <b-button href="/tracker/" class="px-1 planc_button--short" variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/tracker.svg" alt="" />
        Трекер рекламы</b-button
      >
      <!--counter-button
        :counter="getOrdersCount"
        :to="{ name: 'promobot-orders' }"
        class="px-1 planc_button--short"
        variant="outline-default"
        ><img
          class="d-inline-block"
          src="@main/assets/img/svg/planc_icons/applications.svg"
          alt=""
        />
        Заявки на рекламу</!--counter-button
      -->
      <b-button
        :to="{ name: 'my_statistic' }"
        class="px-1 planc_button--short"
        variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/mycard.svg" alt="" /> Моя
        карточка</b-button
      >
      <!--counter-button
        :counter="getAnswersCount"
        :to="{ path: '/promobot/orders/answers/' }"
        class="px-1 planc_button--short"
        variant="outline-default"
      >
        <span class="mx-1">🎩</span> Ответы по рекламе</!--counter-button
      -->
      <!--b-button
        disabled
        v-b-tooltip.hover
        title="Скоро!"
        class="px-1 planc_button--short"
        variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/analytic.svg" alt="" />
        Прогноз прихода</!--b-button
      -->
    </div>
    <div class="d-flex flex-wrap mt-3">
      <!--b-button
        class="w-100 px-1 text-left planc_button--long"
        variant="outline-default"
        :to="{ name: 'create-infoproduct' }"
        ><img
          style="width: 22px; height: 22px"
          class="d-inline-block mr-2"
          src="@main/assets/img/icons/infoproducts.png"
          alt=""
        />Создание и запуск инфопродуктов</!--b-button
      -->
      <!--b-button
        :to="{ path: '/referal/' }"
        class="w-100 px-1 text-left planc_button--long"
        style="color: #212529; box-shadow: 0px 3px 10px rgb(249 212 81 / 7%)"
        variant="outline-yellow"
      >
        <span class="mx-1">⚡</span> Получить подписку и проверки бесплатно</!b-button
      -->
      <!--b-button
        :to="{ path: '/reviews/me/' }"
        class="w-100 px-1 text-left planc_button--long"
        variant="outline-default"
      >
        <span class="mx-1">👉</span> Отзывы на мой аккаунт</!--b-button
      -->
      <b-button
        :to="{ name: 'reviews-pricetable' }"
        class="w-100 px-1 text-left planc_button--long"
        variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/avgprice.svg" alt="" />
        Средняя цена за подписчика для разных тем</b-button
      >
    </div>
    <div class="d-flex flex-wrap justify-content-between">
      <b-button
        href="https://korotylka.info/results"
        class="px-1 planc_button--short"
        target="_blank"
        variant="outline-default"
        ><img class="d-inline-block" src="@main/assets/img/svg/planc_icons/rate.png" alt="" />
        Рейтинг менеджеров</b-button
      >
    </div>
  </div>
</template>

<script>
import promobot from '@main/api/promobot';
import CounterButton from '../../reusable/elements/buttons/CounterButton.vue';

export default {
  methods: {
    async getAnswersCount() {
      const answersCount = await promobot.orders.fnc(
        { actions: ['count'] },
        { for_user: true, status: 'AC' }
      );
      return answersCount.result;
    },
    async getOrdersCount() {
      const hiddenCount = await promobot.orders.hiddenCount();
      const ordersCount = await promobot.orders.fnc({ actions: ['count'] }, { status: 'NEW' });
      return ordersCount.result + hiddenCount;
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 320px) {
  .planc_button--short {
    width: 49% !important;
  }
}
@media (max-width: 360px) {
  .planc_button--short {
    font-size: 13px !important;
  }
  .planc_button--long {
    font-size: 13px !important;
  }
}
@media (max-width: 337px) {
  .planc_button--short {
    font-size: 12px !important;
  }
  .planc_button--long {
    font-size: 12px !important;
  }
}
.planc_button--short {
  width: 100%;
  line-height: 22px !important;
  text-align: left !important;
  vertical-align: middle !important;
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
}
.planc_button--long {
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
}
</style>
